import * as Yup from "yup";

export const ProductInfoSchema = Yup.object().shape({
  id: Yup.string().required(),
  brand: Yup.string().defined(),
  description: Yup.string().defined(),
  department: Yup.string().defined(),
  item_code: Yup.string().defined(),
  barcode: Yup.string().defined(),
  last_cost: Yup.number().defined(),
  size: Yup.string().defined(),
  case_qty: Yup.number().defined(),
});

export type ProductInfo = Yup.InferType<typeof ProductInfoSchema>;
