import * as Yup from "yup";

export const ProductPricingSchema = Yup.object().shape({
  current_cost: Yup.number(),
  future_cost: Yup.number(),

  volume_discounts: Yup.array().of(
    Yup.object().shape({
      cost: Yup.number().required(),
      min_units: Yup.number().integer().required(),
    }),
  ),
});

export type ProductPricing = Yup.InferType<typeof ProductPricingSchema>;
