import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

type PrintContainerProperties = {
  children: React.ReactNode;
  onAfterPrint: () => void;
};

export function PrintContainer({
  children,
  onAfterPrint,
}: PrintContainerProperties) {
  const printReference = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: printReference,
    onAfterPrint,
  });
  useEffect(() => {
    handlePrint();
  }, [handlePrint]);

  return (
    <div ref={printReference} className="w-full h-full hidden print:block">
      {children}
    </div>
  );
}
