import { PrinterIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import type { Product } from "../data/product";
import { PrimaryButton } from "./buttons";
import { PrintContainer } from "./print-container";
import { PrintTable } from "./printable-table";
import type { ColumnDefinition } from "./virtualized-table/column-definition";

export interface PrintButtonProps {
  products: Product[];
  columns: ColumnDefinition<Product>[];
}

export const PrintButton: React.FC<PrintButtonProps> = (properties) => {
  const [printing, setPrinting] = useState(false);

  return (
    <>
      <PrimaryButton onClick={() => setPrinting(true)}>
        <PrinterIcon className="h-5 w-5" aria-hidden="true" />
      </PrimaryButton>
      {printing && (
        <PrintContainer onAfterPrint={() => setPrinting(false)}>
          <PrintTable
            columns={properties.columns}
            items={properties.products}
          />
        </PrintContainer>
      )}
    </>
  );
};
