import { ProductCSVLink } from "../../components/csv-download-button";
import { PrintButton } from "../../components/print-button";
import {
  HideDisabledFilter,
  SearchField,
} from "../../components/product-table/components";
import {
  Actions,
  ItemCode,
  ProductName,
  QOH,
  Reorder,
  Size,
  Type,
  VendorName,
  getUnitsSoldColumn,
} from "../../components/product-table/product-column-definitions";
import { RowRenderer } from "../../components/product-table/row-renderer";
import { Toolbar } from "../../components/toolbar";
import { VirtualizedTable } from "../../components/virtualized-table";
import type { ColumnDefinition } from "../../components/virtualized-table/column-definition";
import { ProductTableContext } from "../../contexts/product-table-context";
import type { Product } from "../../data/product";
import { useFilteredProducts } from "../../hooks/products";

const columns: ColumnDefinition<Product>[] = [
  VendorName,
  ItemCode,
  ProductName,
  Type,
  Size,
  QOH,
  getUnitsSoldColumn(90),
  Reorder,
  Actions,
];

const defaultFilters = { maximumInventory: -1 };

export function NegativeInventoryReport({ products }: { products: Product[] }) {
  const { filters, setFilters, filteredProducts } = useFilteredProducts(
    products,
    defaultFilters,
  );

  return (
    <>
      <ProductTableContext.Provider
        value={{ filters, setFilters, filteredProducts }}
      >
        <Toolbar>
          <HideDisabledFilter />
          <SearchField />
          <PrintButton products={filteredProducts} columns={columns} />
          <ProductCSVLink
            products={filteredProducts}
            columns={columns}
            filename="negative-inventory.csv"
          />
        </Toolbar>
      </ProductTableContext.Provider>
      <VirtualizedTable
        items={filteredProducts}
        columns={columns}
        rowRenderer={RowRenderer}
      />
    </>
  );
}
