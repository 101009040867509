import type React from "react";
import { useMemo } from "react";
import ReactSelect from "react-select";
import type { CSSObjectWithLabel, GroupBase, Props } from "react-select";
import {
  checkboxClass,
  checkboxInvalidClass,
  inputClass,
  inputInvalidClass,
} from "./form-styles";

type ReactLabel = React.FC<React.LabelHTMLAttributes<HTMLLabelElement>>;

export const Label: ReactLabel = (properties) => (
  // biome-ignore lint/a11y/noLabelWithoutControl: false positive
  <label
    className="block text-sm font-medium leading-6 text-gray-900"
    {...properties}
  />
);

type ReactH2 = React.FC<React.LabelHTMLAttributes<HTMLElement>>;

export const Section: ReactH2 = (properties) => (
  <h2
    className="block text-sm font-medium leading-6 text-gray-500"
    {...properties}
  />
);

interface FieldProperties
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  label: string;
  errorMessage?: string;
  name: string;
}

export const Field: React.FC<FieldProperties> = ({
  label,
  children,
  errorMessage,
  name,
}) => (
  <div className="grid grid-flow-row">
    <Label htmlFor={name}>{label}</Label>
    {children}
    {errorMessage && <div className="text-red-500">{errorMessage}</div>}
  </div>
);

type ContainerProperties = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const Row: React.FC<ContainerProperties> = ({ children }) => (
  <div className="grid grid-flow-col justify-start gap-2">{children}</div>
);

export const FieldDescription: React.FC<ContainerProperties> = ({
  children,
}) => <p className="text-gray-400">{children}</p>;

interface InputProperties extends React.InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean;
  className?: string;
}

export const Input: React.FC<InputProperties> = ({
  invalid,
  className,
  ...rest
}) => {
  const fullClassName = useMemo(
    () => `${invalid ? inputInvalidClass : inputClass} ${className}`,
    [invalid, className],
  );

  return <input className={fullClassName} {...rest} />;
};

export const Checkbox: React.FC<InputProperties> = ({
  invalid,
  className,
  ...rest
}) => {
  const fullClassName = useMemo(
    () => `${invalid ? checkboxInvalidClass : checkboxClass} ${className}`,
    [invalid, className],
  );

  return <input type="checkbox" className={fullClassName} {...rest} />;
};

type SelectProperties = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

export const Select: React.FC<SelectProperties> = ({
  children,
  className,
  ...rest
}) => (
  <select
    className={`p-2 mx-1 w-full rounded border-0 ring-1 ring-inset ring-gray-300 bg-white ${className}`}
    {...rest}
  >
    {children}
  </select>
);

export function MultiSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(properties: Props<Option, IsMulti, Group>) {
  return (
    <ReactSelect
      {...properties}
      closeMenuOnSelect={!properties.isMulti}
      styles={{
        menu: (base: CSSObjectWithLabel) => ({
          ...base,
          width: "max-content",
          minWidth: "100%",
        }),
        input: (base: CSSObjectWithLabel) => ({
          ...base,
          "& input": {
            boxShadow: "none !important",
          },
        }),
        valueContainer: (base: CSSObjectWithLabel) => ({
          ...base,
          padding: "0 6px",
        }),
      }}
      classNames={{
        control: () =>
          "!border-0 ring-1 ring-inset ring-gray-300 min-w-[100px]",
      }}
      className="min-w-52"
    />
  );
}
