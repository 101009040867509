import { useContext } from "react";
import {
  NavLink,
  type NavLinkProps,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { LoadingOverlay } from "../../components/loading";
import { StoreProductContext } from "../../contexts/store-product-context";
import { LowStockReport } from "./low-stock";
import { NegativeInventoryReport } from "./negative-inventory";
import { PhantomInventoryReport } from "./phantom-inventory";
import { PopularityReport } from "./popularity";
import { PreviousDaySalesReport } from "./previous-day-sales";
import { SalesVelocityReport } from "./sales-velocity";
import { UnderperformingInventoryReport } from "./underperforming-inventory";

const tabBaseStyles =
  "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium";
const selectedTabStyles = `${tabBaseStyles} border-emerald-500 text-emerald-600`;
const unselectedTabStyles = `${tabBaseStyles} border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700`;

const Tab: React.FC<NavLinkProps> = ({ to, children }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => {
        return isActive ? selectedTabStyles : unselectedTabStyles;
      }}
    >
      {children}
    </NavLink>
  );
};

export function Reports() {
  const { loading, saving, products } = useContext(StoreProductContext);

  return (
    <>
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          <Tab to="sales-velocity-report">Sales Velocity</Tab>
          <Tab to="popularity">Popularity</Tab>
          <Tab to="phantom-inventory">Phantom Inventory</Tab>
          <Tab to="underperforming-inventory">Underperforming Inventory</Tab>
          <Tab to="negative-inventory">Negative Inventory</Tab>
          <Tab to="previous-day-sales">Previous Day Sales</Tab>
          <Tab to="low-stock">Low Stock</Tab>
        </nav>
      </div>
      <Routes>
        <Route
          path="/sales-velocity-report"
          element={<SalesVelocityReport products={products} />}
        />
        <Route
          path="/popularity"
          element={<PopularityReport products={products} />}
        />
        <Route
          path="/phantom-inventory"
          element={<PhantomInventoryReport products={products} />}
        />
        <Route
          path="/underperforming-inventory"
          element={<UnderperformingInventoryReport products={products} />}
        />
        <Route
          path="/negative-inventory"
          element={<NegativeInventoryReport products={products} />}
        />
        <Route
          path="/previous-day-sales"
          element={<PreviousDaySalesReport products={products} />}
        />
        <Route
          path="/low-stock"
          element={<LowStockReport products={products} />}
        />
        <Route
          path="*"
          element={<Navigate to="sales-velocity-report" replace />}
        />
      </Routes>
      {(loading || saving) && <LoadingOverlay />}
    </>
  );
}
