import { Portal } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useContext } from "react";
import { type Alert, AlertContext } from "../contexts/alert-context";

function AlertDisplay({
  alert,
  onClose,
}: {
  alert: Alert;
  onClose: () => void;
}) {
  return (
    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {alert.kind === "Success" && (
              <CheckCircleIcon
                className="h-6 w-6 text-emerald-400"
                aria-hidden="true"
              />
            )}
            {alert.kind === "Error" && (
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-400"
                aria-hidden="true"
              />
            )}
            {alert.kind === "Info" && (
              <InformationCircleIcon
                className="h-6 w-6 text-blue-400"
                aria-hidden="true"
              />
            )}
            {alert.kind === "Warning" && (
              <ExclamationCircleIcon
                className="h-6 w-6 text-orange-400"
                aria-hidden="true"
              />
            )}
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">{alert.message}</p>
            <p className="mt-1 text-sm text-gray-500">{alert.details}</p>
          </div>
          <div className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function AlertsView() {
  const { alerts, removeAlert } = useContext(AlertContext);

  return (
    <Portal>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-20"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {alerts.map((alert: Alert, index) => (
            <AlertDisplay
              alert={alert}
              key={index}
              onClose={() => removeAlert(alert)}
            />
          ))}
        </div>
      </div>
    </Portal>
  );
}

export default AlertsView;
