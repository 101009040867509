import { PrinterIcon } from "@heroicons/react/20/solid";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FourOhFour } from "../../components/404";
import {
  PrimaryButton,
  SecondaryButton,
  SecondaryLinkButton,
} from "../../components/buttons";
import { Loading } from "../../components/loading";
import { Modal } from "../../components/modal";
import { PrintContainer } from "../../components/print-container";
import { StoreOrderContext } from "../../contexts/store-order-context";
import { useOrder } from "../../hooks/orders";
import { useSelectedStore } from "../../hooks/selected-store";
import { useVendor } from "../../hooks/vendors";
import { PurchaseOrder } from "./purchase-order";

export function OrderSummary() {
  const { selectedStore, loading: storeLoading } = useSelectedStore();
  const { orderId } = useParams();
  const { order, loading: orderLoading } = useOrder(selectedStore?.id, orderId);
  const { vendor, loading: vendorLoading } = useVendor(order?.vendor_id);
  const { createOrder } = useContext(StoreOrderContext);
  const navigate = useNavigate();

  const [duplicateConfirmationIsOpen, setDuplicateConfirmationIsOpen] =
    useState(false);

  const [printing, setPrinting] = useState(false);

  // Redirect to the orders page if the order is not found. This typically happens when switching from one
  // store to another.
  useEffect(() => {
    if (orderLoading || vendorLoading || storeLoading) {
      return;
    }

    if (!order && selectedStore) {
      navigate(`/stores/${selectedStore.id}/orders`);
    }
  }, [
    selectedStore,
    navigate,
    order,
    orderLoading,
    storeLoading,
    vendorLoading,
  ]);

  if (orderLoading || vendorLoading || storeLoading) {
    return <Loading />;
  }

  if (!order || !vendor || !selectedStore) {
    return <FourOhFour />;
  }

  const duplicateOrder = () => {
    createOrder(selectedStore.id, {
      vendor_id: vendor.vendor_id,
      options: order.options,
      items: order.items,
    })
      .then((newOrder) => {
        navigate(`/stores/${selectedStore.id}/orders/${newOrder.id}/edit`);
      })
      .catch(() => {
        alert("Error duplicating order");
      });

    setDuplicateConfirmationIsOpen(false);
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="max-w-[1000px]">
          <div className="p-4 grid grid-cols-2">
            <div className="justify-self-start">
              <SecondaryLinkButton to={`/stores/${selectedStore.id}/orders`}>
                Back to Orders
              </SecondaryLinkButton>
            </div>
            <div className="flex flex-row gap-2 justify-self-end">
              <PrimaryButton
                onClick={() => setDuplicateConfirmationIsOpen(true)}
              >
                Duplicate Order
              </PrimaryButton>
              <PrimaryButton onClick={() => setPrinting(true)}>
                <PrinterIcon className="h-5 w-5" aria-hidden="true" />
              </PrimaryButton>
            </div>
            <div className="pt-4 col-span-2 border-b border-b-gray-300" />
          </div>
          <PurchaseOrder
            store={selectedStore}
            vendor={vendor}
            order={order}
            orderItems={order.items}
          />
          {printing && (
            <PrintContainer onAfterPrint={() => setPrinting(false)}>
              <PurchaseOrder
                store={selectedStore}
                vendor={vendor}
                order={order}
                orderItems={order.items}
              />
            </PrintContainer>
          )}
        </div>
      </div>
      <Modal
        visible={duplicateConfirmationIsOpen}
        title="Are you sure you want to duplicate this order?"
        onClose={() => setDuplicateConfirmationIsOpen(false)}
      >
        <div className="text-sm max-w-96">
          <p className="pb-4">
            This order has already been sent to{" "}
            <span className="font-semibold">{vendor.name}</span>. You are
            responsible for canceling this order with{" "}
            <span className="font-semibold">{vendor.name}</span> to avoid
            duplicate shipments.
          </p>
          <p className="pb-4">
            Duplicating this order will create a new order with the same items
            and options.
          </p>
          <p className="pb-4">Do you want to continue?</p>
        </div>
        <div className="flex justify-end gap-2">
          <SecondaryButton
            onClick={() => setDuplicateConfirmationIsOpen(false)}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={() => duplicateOrder()}>
            Duplicate
          </PrimaryButton>
        </div>
      </Modal>
    </>
  );
}
