export const compactCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  notation: "compact",
}).format;

export const shortCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
}).format;

export const preciseCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  notation: "compact",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format;

export const fullCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format;

export const combinedCurrencyFormatter = (amount: number) => {
  if (amount < 900 || amount > 1_000_000) {
    return preciseCurrencyFormatter(amount);
  }

  return compactCurrencyFormatter(amount);
};

export const formatPercent = (percent: number) => {
  return `${(percent * 100).toFixed(1)}%`;
};
