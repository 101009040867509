import { useMemo } from "react";

type Properties = {
  children: React.ReactNode;
  layout?: string;
};

export function Toolbar({ children, layout = "" }: Properties) {
  const className = useMemo(
    () => `flex flex-row gap-4 p-2 justify-end items-center ${layout}`,
    [layout],
  );
  return <div className={className}>{children}</div>;
}
