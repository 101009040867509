import * as Yup from "yup";
import { AddressSchema } from "./address";
import { StoreSalesStatsSchema } from "./store-sales-stat";

export const StoreSchema = Yup.object().shape({
  id: Yup.string().required(),
  product_key: Yup.string().required(),
  owner_first_name: Yup.string().required(),
  owner_last_name: Yup.string().required(),
  phone_number: Yup.string().defined(),
  billing_address: AddressSchema.required(),
  shipping_address: AddressSchema.required(),
  name: Yup.string().required(),
  emails: Yup.array().of(Yup.string().email().required()).required(),
  enabled: Yup.boolean().required(),
  sales_stats: StoreSalesStatsSchema.required(),

  created_at: Yup.date().required(),
  updated_at: Yup.date().required(),
});

export type Store = Yup.InferType<typeof StoreSchema>;
