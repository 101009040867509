import * as Yup from "yup";
import { type SaleStatDays, saleStatDays } from "./product";

// form validation rules
export const OrderOptionsSchema = Yup.object().shape({
  qoh_threshold_percent: Yup.number().required(),
  sales_period_days: Yup.mixed<SaleStatDays>()
    .oneOf(saleStatDays)
    .defined()
    .required(),
  reorder_weeks: Yup.number().required(),
  fudge_percent: Yup.number().defined(),

  send_methods: Yup.array().of(Yup.string().required()).required(),
  emails: Yup.array().of(Yup.string().email().required()).required(),
});

export type OrderOptions = Yup.InferType<typeof OrderOptionsSchema>;
