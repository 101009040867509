import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Loading } from "../components/loading";

export const AuthenticationGuard = ({
  component,
}: {
  component: React.ComponentType<object>;
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  return <Component />;
};

export default AuthenticationGuard;
