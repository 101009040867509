import { ProductCSVLink } from "../../components/csv-download-button";
import { PrintButton } from "../../components/print-button";
import { SearchField } from "../../components/product-table/components";
import {
  Brand,
  Department,
  Description,
  Done,
  LastReceived,
  ProductName,
  QOH,
  Size,
  SizeOunces,
  Type,
  VendorName,
  getUnitsSoldColumn,
} from "../../components/product-table/product-column-definitions";
import { RowRenderer } from "../../components/product-table/row-renderer";
import { Toolbar } from "../../components/toolbar";
import { VirtualizedTable } from "../../components/virtualized-table";
import type { ColumnDefinition } from "../../components/virtualized-table/column-definition";
import {
  type ColumnSort,
  multiSort,
} from "../../components/virtualized-table/column-sort";
import { ProductTableContext } from "../../contexts/product-table-context";
import type { Product } from "../../data/product";
import {
  type Filters,
  useFilteredProducts,
  useSortedProducts,
} from "../../hooks/products";

const columns: ColumnDefinition<Product>[] = [
  Department,
  VendorName,
  ProductName,
  Type,
  Size,
  QOH,
  LastReceived,
  getUnitsSoldColumn(1),
];

const sortColumns: ColumnSort<Product>[] = [
  {
    column: Department,
    direction: "asc",
  },
  {
    column: SizeOunces,
    direction: "desc",
  },
  {
    column: Type,
    direction: "asc",
  },
  {
    column: Brand,
    direction: "asc",
  },
  {
    column: Description,
    direction: "desc",
  },
];

const printColumns: ColumnDefinition<Product>[] = [...columns, Done];

const defaultFilters: Filters = { soldIn: 1, minimumInventory: 1 };

export function PreviousDaySalesReport({ products }: { products: Product[] }) {
  const { filters, setFilters, filteredProducts } = useFilteredProducts(
    products,
    defaultFilters,
  );
  const sortedProducts = useSortedProducts(
    filteredProducts,
    multiSort(sortColumns),
  );

  return (
    <>
      <ProductTableContext.Provider
        value={{ filters, setFilters, filteredProducts }}
      >
        <Toolbar>
          <SearchField />
          <PrintButton products={sortedProducts} columns={printColumns} />
          <ProductCSVLink
            products={sortedProducts}
            columns={columns}
            filename="previous-day-sales.csv"
          />
        </Toolbar>
      </ProductTableContext.Provider>
      <VirtualizedTable
        items={sortedProducts}
        columns={columns}
        rowRenderer={RowRenderer}
      />
    </>
  );
}
