import type { Auth0ContextInterface } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import * as Yup from "yup";

export class FetchWrapper {
  tokenAccessor: Auth0ContextInterface["getAccessTokenSilently"];

  constructor(tokenAccessor: Auth0ContextInterface["getAccessTokenSilently"]) {
    this.tokenAccessor = tokenAccessor;
  }

  get(url: string) {
    const requestOptions = {
      method: "GET",
    };
    return this.doFetch(url, requestOptions);
  }

  post(url: string, body: unknown) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    return this.doFetch(url, requestOptions);
  }

  put(url: string, body: unknown) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    return this.doFetch(url, requestOptions);
  }

  delete(url: string) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.doFetch(url, requestOptions);
  }

  async doFetch(url: string, options: RequestInit) {
    const token = await this.tokenAccessor();

    const requestHeaders: Headers = new Headers(options.headers);
    requestHeaders.set("Authorization", `Bearer ${token}`);
    options.headers = requestHeaders;

    return fetch(url, options);
  }
}

export const createSingleHandler = <T extends Yup.Maybe<Yup.AnyObject>>(
  schema: Yup.ObjectSchema<T>,
) => {
  return (response: Response) => handleSingleResponse(response, schema);
};

export const createMultipleHandler = <T extends Yup.Maybe<Yup.AnyObject>>(
  schema: Yup.ObjectSchema<T>,
) => {
  return (response: Response) => handleMultipleResponse(response, schema);
};

export const handleSingleResponse = <T extends Yup.Maybe<Yup.AnyObject>>(
  response: Response,
  schema: Yup.ObjectSchema<T>,
) => {
  return response.json().then((json) => {
    if (!response.ok) {
      const error = new Error(
        `Invalid response: ${json?.message || response.statusText}`,
      );
      datadogRum.addError(error);

      throw error;
    }

    return schema.json().validate(json);
  });
};

export const handleMultipleResponse = <T extends Yup.Maybe<Yup.AnyObject>>(
  response: Response,
  schema: Yup.ObjectSchema<T>,
) => {
  return response.json().then((json) => {
    if (!response.ok) {
      const error = new Error(
        `Invalid response: ${json?.message || response.statusText}`,
      );
      datadogRum.addError(error);

      throw error;
    }

    return Yup.array()
      .required()
      .compact((item) => {
        try {
          schema.validateSync(item);
        } catch (error) {
          datadogRum.addError(error);

          return true;
        }

        return false;
      })
      .of(schema.required())
      .validate(json);
  });
};
