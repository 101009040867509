import { useMemo } from "react";
import { ProductCSVLink } from "../../components/csv-download-button";
import { PrintButton } from "../../components/print-button";
import {
  DepartmentFilter,
  HideDisabledFilter,
  SearchField,
  TypeFilter,
  VendorNameFilter,
} from "../../components/product-table/components";
import {
  Actions,
  DOH,
  ItemCode,
  LastReceived,
  LastSold,
  MarginPercentage,
  OrderLot,
  ProductName,
  QOHCases,
  Reorder,
  Size,
  Type,
  VendorName,
  getCasesSoldColumn,
} from "../../components/product-table/product-column-definitions";
import { RowRenderer } from "../../components/product-table/row-renderer";
import { Toolbar } from "../../components/toolbar";
import { VirtualizedTable } from "../../components/virtualized-table";
import type { ColumnDefinition } from "../../components/virtualized-table/column-definition";
import { multiSort } from "../../components/virtualized-table/column-sort";
import { ProductTableContext } from "../../contexts/product-table-context";
import type { Product } from "../../data/product";
import {
  type Filters,
  useFilteredProducts,
  useSortedProducts,
} from "../../hooks/products";

const defaultFilters: Filters = {
  soldIn: 365,
  minimumInventory: 0,
};

export function SalesVelocityReport({ products }: { products: Product[] }) {
  const { filters, setFilters, filteredProducts } = useFilteredProducts(
    products,
    defaultFilters,
  );

  const casesSoldColumn = useMemo(
    () => getCasesSoldColumn(filters.soldIn || 365),
    [filters],
  );

  const makeSort = (casesSoldColumn: ColumnDefinition<Product>) => {
    return multiSort([
      {
        column: casesSoldColumn,
        direction: "asc",
      },
    ]);
  };

  const sortedProducts = useSortedProducts(
    filteredProducts,
    makeSort(casesSoldColumn),
  );

  const columns = useMemo(() => {
    return [
      VendorName,
      ItemCode,
      ProductName,
      Type,
      Size,
      OrderLot,
      Reorder,
      MarginPercentage,
      LastReceived,
      LastSold,
      QOHCases,
      DOH,
      casesSoldColumn,
      Actions,
    ];
  }, [casesSoldColumn]);

  return (
    <>
      <ProductTableContext.Provider
        value={{ filters, setFilters, filteredProducts }}
      >
        <Toolbar>
          <HideDisabledFilter />
          <VendorNameFilter products={products} />
          <DepartmentFilter products={products} />
          <TypeFilter products={products} />
          <SearchField />
          <PrintButton products={sortedProducts} columns={columns} />
          <ProductCSVLink
            products={sortedProducts}
            columns={columns}
            filename="sales-velocity.csv"
          />
        </Toolbar>
      </ProductTableContext.Provider>
      <VirtualizedTable
        items={sortedProducts}
        columns={columns}
        rowRenderer={RowRenderer}
      />
    </>
  );
}
