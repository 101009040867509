import { StoreSalesStatsDisplay } from "../../components/dashboard/store-sales-stats-display";
import { DownloadUploaderButton } from "../../components/download-uploader-button";
import { NoResults } from "../../components/no-results";
import { useSelectedStore } from "../../hooks/selected-store";

export function Dashboard() {
  const { loading, selectedStore } = useSelectedStore();

  return (
    <>
      {!loading &&
        selectedStore &&
        selectedStore.sales_stats.historical.length === 0 && (
          <NoResults label="Your store doesn't have any data to display yet.">
            <DownloadUploaderButton />
          </NoResults>
        )}
      {!loading && !selectedStore?.enabled && (
        <NoResults label="Your store is disabled">
          <p>
            Please go to Manage Billing in the menu or contact us at{" "}
            <a
              href="mailto:hello@liquorstore.tech"
              className="text-emerald-500"
            >
              hello@liquorstore.tech
            </a>{" "}
            to re-enable your store.
          </p>
        </NoResults>
      )}
      {selectedStore?.enabled &&
        selectedStore.sales_stats.historical.length > 0 && (
          <div className="h-full w-full flex flex-col">
            <div className="flex flex-wrap gap-1 justify-start items-start content-start p-4 bg-gray-200 h-full">
              <StoreSalesStatsDisplay
                store={selectedStore}
                displayType="Sales"
              />
              <StoreSalesStatsDisplay
                store={selectedStore}
                displayType="Gross Margin"
              />
              <StoreSalesStatsDisplay
                store={selectedStore}
                displayType="Transactions"
              />
            </div>
          </div>
        )}
    </>
  );
}
