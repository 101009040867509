import type { Auth0ContextInterface } from "@auth0/auth0-react";
import { differenceInDays } from "date-fns/differenceInDays";
import { type CreateOrder, type Order, OrderSchema } from "../data/order";
import { type OrderItem, OrderItemSchema } from "../data/order-item";
import { type OrderOptions, OrderOptionsSchema } from "../data/order-options";
import { type Product, ProductSchema, saleStat } from "../data/product";
import { type Store, StoreSchema } from "../data/store";
import { type Vendor, VendorSchema } from "../data/vendor";
import {
  FetchWrapper,
  createMultipleHandler,
  createSingleHandler,
} from "../helpers/fetch-wrapper";

const baseUrl = `${import.meta.env.VITE_SERVER_URL}/stores`;

export type StoreCreateInput = {
  owner_first_name: string;
  owner_last_name: string;

  name: string;
};

export class StoreService {
  fetchWrapper: FetchWrapper;

  constructor(tokenAccessor: Auth0ContextInterface["getAccessTokenSilently"]) {
    this.fetchWrapper = new FetchWrapper(tokenAccessor);
  }

  getAll(): Promise<Store[]> {
    return this.fetchWrapper
      .get(baseUrl)
      .then(createMultipleHandler(StoreSchema))
      .then((stores: Store[]) => {
        return stores.sort((s1, s2) => s1.name.localeCompare(s2.name));
      });
  }

  getById(id: string): Promise<Store> {
    return this.fetchWrapper
      .get(`${baseUrl}/${id}`)
      .then(createSingleHandler(StoreSchema));
  }

  update(id: string, parameters: unknown): Promise<Store> {
    return this.fetchWrapper
      .put(`${baseUrl}/${id}`, parameters)
      .then(createSingleHandler(StoreSchema));
  }

  // vendors

  getVendorsByStore(storeId: string): Promise<Vendor[]> {
    return this.fetchWrapper
      .get(`${baseUrl}/${storeId}/vendors`)
      .then(createMultipleHandler(VendorSchema))
      .then((vendors: Vendor[]) => {
        return vendors.sort((v1, v2) => v1.name.localeCompare(v2.name));
      });
  }

  updateVendor(storeId: string, vendor: Vendor): Promise<Vendor> {
    return this.fetchWrapper
      .put(`${baseUrl}/${storeId}/vendors/${vendor.vendor_id}`, vendor)
      .then(createSingleHandler(VendorSchema));
  }

  // products

  getProductsByStore(storeId: string): Promise<Product[]> {
    return this.fetchWrapper
      .get(`${baseUrl}/${storeId}/products`)
      .then(createMultipleHandler(ProductSchema))
      .then((products: Product[]) => {
        const today = new Date();

        return products.map((product) => {
          const noStock = product.inventory_qty <= 0;
          const notReceived =
            !product.last_received ||
            differenceInDays(today, product.last_received) >= 365;
          const notSold = saleStat(product, 365) === 0;

          if (noStock && notReceived && notSold) {
            product.auto_disabled = true;
          }

          return product;
        });
      });
  }

  updateProduct(storeId: string, product: Product): Promise<Product> {
    return this.fetchWrapper
      .put(
        `${baseUrl}/${storeId}/vendors/${product.vendor_id}/products/${product.id}`,
        product,
      )
      .then(createSingleHandler(ProductSchema));
  }

  // orders

  getOrdersByStore(storeId: string): Promise<Order[]> {
    return this.fetchWrapper
      .get(`${baseUrl}/${storeId}/orders?status=draft,sending,sent,failed`)
      .then(createMultipleHandler(OrderSchema));
  }

  getOrderByStore(storeId: string, orderId: string): Promise<Order> {
    return this.fetchWrapper
      .get(`${baseUrl}/${storeId}/orders/${orderId}`)
      .then(createSingleHandler(OrderSchema));
  }

  createOrder(storeId: string, order: CreateOrder): Promise<Order> {
    return this.fetchWrapper
      .post(`${baseUrl}/${storeId}/orders`, order)
      .then(createSingleHandler(OrderSchema));
  }

  submitOrder(storeId: string, order: Order, method: string): Promise<Order> {
    return this.fetchWrapper
      .post(`${baseUrl}/${storeId}/orders/${order.id}/submit`, {
        order,
        send_method: method,
      })
      .then(createSingleHandler(OrderSchema));
  }

  saveOrderOptions(
    storeId: string,
    orderId: string,
    options: OrderOptions,
  ): Promise<OrderOptions> {
    return this.fetchWrapper
      .put(`${baseUrl}/${storeId}/orders/${orderId}/options`, options)
      .then(createSingleHandler(OrderOptionsSchema));
  }

  updateOrderItem(
    storeId: string,
    orderId: string,
    orderItem: OrderItem,
  ): Promise<OrderItem> {
    return this.fetchWrapper
      .put(`${baseUrl}/${storeId}/orders/${orderId}/item`, orderItem)
      .then(createSingleHandler(OrderItemSchema));
  }

  async deleteOrder(order: Order): Promise<boolean> {
    await this.fetchWrapper.delete(
      `${baseUrl}/${order.store_id}/orders/${order.id}`,
    );
    return true;
  }
}
