import { useAuth0 } from "@auth0/auth0-react";
import { differenceInDays } from "date-fns/differenceInDays";
import Fuse, { type Expression } from "fuse.js";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AlertContext } from "../contexts/alert-context";
import { type Product, type SaleStatDays, saleStat } from "../data/product";
import { StoreService } from "../services/stores";
import { useSelectedStore } from "./selected-store";

export function useProducts() {
  const { addErrorAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [products, setProducts] = useState<Product[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  const storeService = useMemo(
    () => new StoreService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const { selectedStore } = useSelectedStore();

  useEffect(() => {
    if (!selectedStore) {
      return;
    }
    setLoading(true);
    // flush previous products so we don't show stale data during fetch
    setProducts([]);

    storeService
      .getProductsByStore(selectedStore.id)
      .then(setProducts)
      .catch((error: Error) => {
        setError(error.message);
        addErrorAlert(`Invalid products: ${error.message}`);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedStore, storeService, addErrorAlert]);

  const updateProduct = useCallback(
    (product: Product) => {
      setSaving(true);
      return storeService
        .updateProduct(product.store_id, product)
        .then((updatedProduct: Product) => {
          setProducts((products: Product[]) => {
            // The product update endpoint does not fetch the vendor name / pricing information, unlike the list endpoint
            // so we need to update only select fields
            return products.map((v) =>
              v.id === updatedProduct.id
                ? {
                    ...v,
                    min_qoh: updatedProduct.min_qoh,
                    disabled: updatedProduct.disabled,
                    disabled_toggled_at: updatedProduct.disabled_toggled_at,
                    notes: updatedProduct.notes,
                  }
                : v,
            );
          });
          return updatedProduct;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          setError(error.message);
          throw error;
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [storeService, addErrorAlert],
  );

  return { products, loading, saving, error, updateProduct };
}

export type Filters = {
  departments?: string[];
  hideDisabled?: boolean;
  maximumInventory?: number;
  minimumInventory?: number;
  maximumDOH?: number;
  notSoldIn?: SaleStatDays;
  query?: string;
  soldIn?: SaleStatDays;
  types?: string[];
  vendorNames?: string[];
  displayInCases?: boolean;
};

type ProductSort = (a: Product, b: Product) => number;

function useQueryFilterFunction(
  query: string | undefined,
  products: Product[],
) {
  const productsIndex = useMemo(() => {
    return new Fuse<Product>(products, {
      threshold: 0,
      ignoreLocation: true,
      useExtendedSearch: true,

      keys: [
        "vendor_name",
        "brand",
        "description",
        "department",
        "item_code",
        "barcode",
        "type",
        "size",
      ],
    });
  }, [products]);

  const ids = useMemo(() => {
    if (!query || query === "") {
      return new Set(products.map((product) => product.id));
    }

    function orExpression(q: string): Expression {
      return {
        $or: [
          { item_code: `'${q}` },
          { barcode: `'${q}` },
          { vendor_name: q },
          { brand: q },
          { description: q },
          { department: q },
          { type: q },
          { size: q },
        ],
      };
    }

    const results = productsIndex.search({
      $and: query
        .split(" ")
        .filter((q) => q !== "")
        .map(orExpression),
    });
    return new Set(results.map((result) => result.item.id));
  }, [query, productsIndex, products]);

  return useCallback(
    (product: Product) => {
      return ids.has(product.id);
    },
    [ids],
  );
}

const today = new Date();

function isPhantom(product: Product, notSoldIn: SaleStatDays) {
  return (
    saleStat(product, notSoldIn) === 0 &&
    (!product.last_received ||
      differenceInDays(today, product.last_received) >= notSoldIn)
  );
}

export function useFilteredProducts(
  products: Product[],
  initialFilters: Filters,
) {
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const queryFilterFunction = useQueryFilterFunction(filters.query, products);

  const filteredProducts = useMemo(() => {
    return products.filter((product) => {
      const {
        departments,
        hideDisabled,
        maximumInventory,
        minimumInventory,
        maximumDOH,
        notSoldIn,
        soldIn,
        types,
        vendorNames,
      } = filters;

      if (
        departments &&
        departments.length > 0 &&
        !departments.includes(product.department)
      ) {
        return false;
      }

      if (
        vendorNames &&
        vendorNames.length > 0 &&
        !vendorNames.includes(product.vendor_name)
      ) {
        return false;
      }

      if (soldIn && saleStat(product, soldIn) <= 0) {
        return false;
      }

      // negative inventory report
      if (
        maximumInventory !== undefined &&
        product.inventory_qty > maximumInventory
      ) {
        return false;
      }

      if (
        minimumInventory !== undefined &&
        product.inventory_qty < minimumInventory
      ) {
        return false;
      }

      // low stock report
      if (
        maximumDOH !== undefined &&
        (product.stats.days_on_hand < 0 ||
          product.stats.days_on_hand > maximumDOH)
      ) {
        return false;
      }

      // phantom inventory report
      if (notSoldIn && !isPhantom(product, notSoldIn)) {
        return false;
      }

      if (types && types.length > 0 && !types.includes(product.type)) {
        return false;
      }

      if (
        hideDisabled === true &&
        (product.disabled || product.auto_disabled)
      ) {
        return false;
      }

      return queryFilterFunction(product);
    });
  }, [products, filters, queryFilterFunction]);

  return { filteredProducts, filters, setFilters };
}

export function useSortedProducts(
  products: Product[],
  compareFunction: ProductSort,
) {
  return useMemo(
    () => [...products].sort(compareFunction),
    [products, compareFunction],
  );
}
