import * as Yup from "yup";

// form validation rules
export const ProductStatSchema = Yup.object().shape({
  last_1: Yup.number().required(),
  last_7: Yup.number().required(),
  last_14: Yup.number().required(),
  last_30: Yup.number().required(),
  last_60: Yup.number().required(),
  last_90: Yup.number().required(),
  last_180: Yup.number().required(),
  last_365: Yup.number().required(),

  days_on_hand: Yup.number().required(),
});

export type ProductStat = Yup.InferType<typeof ProductStatSchema>;
